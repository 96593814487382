import React from 'react'
import Routes from "./routes";


export const Home = (() => {

    return (
        <Routes/>
    )

})

export default Home;