import React from "react";
import { Route, BrowserRouter } from "react-router-dom";

import Termotec from "./pages/TermotecPage";
import Veneziana from "./pages/VenezianaPage";

const Routes = () => {
   return(
       <BrowserRouter basename={"/"}>
           <Route component = { Termotec }  path="/termotec" />
           <Route component = { Veneziana }  path="/veneziana"/>
       </BrowserRouter>
   )
}

export default Routes;