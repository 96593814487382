import React, { Suspense, useRef, useState, useEffect } from "react"
import { Canvas, useLoader } from "react-three-fiber"
import { Environment, useGLTF, OrbitControls } from "drei"
import { proxy, useProxy } from "valtio"
import * as THREE from "three"

// Using a Valtio state model to bridge reactivity between
// the canvas and the dom, both can write to it and/or react to it.
const state = proxy({
  current: null,
  items: {
    montante: "natural",
    aleta: "white",
  },
  texture: "./tela.png",
  texture2: "./tela2.png",
  textureProduct: "./branco.png",
  tela: "false",
  telaAletas: "false",
})

function Veneziana() {
  const ref = useRef()
  const snap = useProxy(state)

  const { nodes, materials } = useGLTF("veneziana.glb")

  function getScaledTexture(geometry, fitTo, textureApply) {
    var texture = useLoader(THREE.TextureLoader, textureApply)
    texture.wrapS = THREE.RepeatWrapping
    texture.wrapT = THREE.RepeatWrapping
    texture.mapping = THREE.CubeUVReflectionMapping

    geometry.computeBoundingBox()
    var bbox = geometry.boundingBox
    var dX = Math.abs(bbox.max.x - bbox.min.x)
    var dY = Math.abs(bbox.max.y - bbox.min.y)
    var dZ = Math.abs(bbox.max.z - bbox.min.z)

    var repeat = Math.max(dX, dY, dZ) / fitTo

    texture.repeat.set(repeat, repeat)
    return texture
  }

  let texture3 = ''
  let mapColor = snap.items.montante;
 
  if(snap.items.montante === 'natural'){
     texture3 = getScaledTexture(nodes.montante.geometry, 0.06, snap.textureProduct)
     mapColor = 'white';
  }else if(snap.items.montante === 'aluminio'){
    texture3 = getScaledTexture(nodes.montante.geometry, 0.05, snap.textureProduct)
    mapColor = 'white';
  }else{
    texture3 = getScaledTexture(nodes.montante.geometry, 0.02, snap.textureProduct)
  }


 
  
  const texture = getScaledTexture(nodes.tela.geometry, 1, snap.texture)
  const texture1 = getScaledTexture(nodes.tela.geometry, 0.03, snap.texture)
  const texture2 = getScaledTexture(nodes.telaleta.geometry, 0.01, snap.texture2)

  // Cursor showing current color
  const [hovered, set] = useState(null)
  useEffect(() => {
    document.body.style.cursor = `url('/mouse-veneziana.png'), auto`
  }, [hovered])

  return (
    <group ref={ref} dispose={null}>
      <group rotation={[-1.2, 0, -0.5]}>
        {snap.tela === "true" && (
          <>
            <mesh
              geometry={nodes.tela.geometry}
              material={nodes.tela.material}
              material-map={texture1}
              material-transparent
              material-opacity={0.9}
            />
            <mesh geometry={nodes.tampa.geometry} material={nodes.tampa.material} />
            <mesh geometry={nodes.quadro.geometry} material={nodes.quadro.material} />
          </>
        )}
        <mesh geometry={nodes.aleta.geometry} material={nodes.aleta.material} material-color={snap.items.aleta} />
        {snap.telaAletas === "true" && (
          <mesh geometry={nodes.telaleta.geometry} material={nodes.telaleta.material} material-map={texture2}  material-transparent
              material-opacity={0.9}/>
        )}
        <mesh geometry={nodes.rebite.geometry} material={nodes.rebite.material} />
        <mesh
          geometry={nodes.montante.geometry}
          material={nodes.montante.material}
          material-color={mapColor}
          material-map={texture3}
        />
      </group>
    </group>
  )
}

export default function App(props) {
  state.items.montante = props.montante
  state.items.aleta = props.aleta
  state.texture = "./tela.png"
  state.tela = props.tela
  state.telaAletas = props.telaAletas

  switch (state.items.montante) {

    case 'natural':
        state.textureProduct = './zinco.jpg'
      break;

      case 'aluminio':
        state.textureProduct = './aluminio.png'
      break;
  
    default:
      state.textureProduct = './branco.png'
      break;
  }

  return (
    <>
      <Canvas concurrent pixelRatio={[1, 1.5]} camera={({ position: [0, 0, 1.75] }, { zoom: 4 })}>
        <ambientLight intensity={0.3} />
        <spotLight intensity={0.7} angle={0.1} penumbra={1} position={[5, 25, 20]} />
        <Suspense fallback={null}>
          <Environment files={"ferdr.hdr"}/>
          <Veneziana />
        </Suspense>
        <OrbitControls enableZoom={true} enablePan={false} />
      </Canvas>
    </>
  )
}
