import React from "react"
import ReactDOM, {HashRouter} from "react-dom"
import "./styles.css"
import "react-colorful/dist/index.css"
import Home from "./home"

ReactDOM.render(
    <React.StrictMode>
     <Home />
  </React.StrictMode>,
document.getElementById("root"))
