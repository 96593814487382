import React, {useState,Suspense, useLayoutEffect} from 'react'
import Termotec from '../Termotec'
import { AiOutlineClose } from 'react-icons/ai';
import { GiHamburgerMenu } from 'react-icons/gi';



export const Home = (() => {
  

    const [inferior, setInferior] = useState("#ecece7");
    const [superior, setSuperior] = useState("#ecece7");
    const [eps, setEps] = useState("isopor.png");
    const [visible,setVisible] = useState(true);

    const [produto, setProduto] = useState("termotec");

    function HandleChangeSuperior(event) {
        setSuperior(event.target.value)
    }
      
    function HandleChangeInferior(event) {
        setInferior(event.target.value)
    }
      
      
    function HandleChangeTexture(event) {
        setEps(event.target.value)
    }

    function HandleChangeProduto(event) {
        setProduto(event.target.value)
    }
    const self = this;
    function HandleChangeMenu(event) {
      setVisible(!visible)
    }

    return(
       <div className="main-container">
         {!visible &&
          <GiHamburgerMenu className={"hamburguer"} size={32} onClick={HandleChangeMenu} color={"#12294d"}/>
         }
        <div className={visible ? "active-menu" : "disable-menu"}>
          <AiOutlineClose className={"close-hamburguer"} size={32} color={"white"} onClick={HandleChangeMenu}/>
          <h1 className="menu-titulo">Telha Termotec</h1>
        <div className={"seletores"}>
          <div className={"selects"}>
            <label htmlFor="color1">{produto === 'termotec' ? "Cor Superior" : "Cor Telha"}</label>
            <select name="color" id="color1" onChange={HandleChangeSuperior}>
              <option value="#ecece7">Branco</option>
              <option value="#9b2321">Vermelho</option>
              <option value="#1f3855">Azul</option>
              <option value="green">Verde</option>
              <option value="grey">Cinza</option>
              <option value="#333333">Preto</option>
            </select>
        </div>
      
          <div className={"selects"}>
            <label htmlFor="color2">Cor Inferior</label>
            <select name="color" id="color2" onChange={HandleChangeInferior}>
              <option value="#ecece7">Branco</option>
              <option value="#9b2321">Vermelho</option>
              <option value="#1f3855">Azul</option>
              <option value="green">Verde</option>
              <option value="grey">Cinza</option>
              <option value="#333333">Preto</option>
            </select>
          </div>
        
          <div className={"selects"}>
            <label htmlFor="isolamento">Isolamento</label>
            <select name="isolamento" id="isolamento" onChange={HandleChangeTexture}>
              <option value="isopor.png">Isopor</option>
              <option value="pu.jpg">PU</option>
              <option value="pir.jpg">PIR</option>
            </select>
          </div>
      
        </div>
        </div>
        <div className="produto" className={visible ? "produto" : "produto-focus"}>
          <span className="info">Clique sobre o produto e rotacione.</span>
          <Termotec inferior={inferior} superior={superior} eps={eps} hdr={"ferdr.hdr"}/>
        </div>
       </div>
    );
})

export default Home;