import React, { Suspense, useRef, useState, useEffect } from "react"
import { Canvas, useFrame, useLoader} from "react-three-fiber"
import { ContactShadows, Environment, useGLTF, OrbitControls } from "drei"
import { proxy, useProxy } from "valtio"
import * as THREE from "three";


// Using a Valtio state model to bridge reactivity between
// the canvas and the dom, both can write to it and/or react to it.
const state = proxy({
  current: null,
  items: {
    superior: "white",
    inferior: "white",
    laces: "#ffffff",
    mesh: "#ffffff",
    caps: "#ffffff",
    inner: "#ffffff",
    sole: "#ffffff",
    stripes: "#ffffff",
    band: "#ffffff",
    patch: "#ffffff",
  },
  texture: "isopor.png",
  hdr: ""
})


function Termotec() {
  const ref = useRef()
  const snap = useProxy(state)
  // Drei's useGLTF hook sets up draco automatically, that's how it differs from useLoader(GLTFLoader, url)
  // { nodes, materials } are extras that come from useLoader, these do not exist in threejs/GLTFLoader
  // nodes is a named collection of meshes, materials a named collection of materials
  const { nodes, materials } = useGLTF("termo2.glb")
  console.log(useGLTF("termo2.glb"));

  // texture loader
  const repeatX = 1;
  const repeatY = 1;

  //const texture = useLoader(THREE.TextureLoader,snap.texture);
  //texture.wrapS = THREE.RepeatWrapping;
  //texture.wrapT = THREE.RepeatWrapping;
  //texture.repeat.set(repeatX, repeatY);

  function getScaledTexture(geometry,fitTo) {
    var texture = useLoader(THREE.TextureLoader,snap.texture);
      texture.wrapS = THREE.RepeatWrapping;
      texture.wrapT = THREE.RepeatWrapping;
    texture.mapping = THREE.CubeUVReflectionMapping;
    
    geometry.computeBoundingBox ();
    var bbox = geometry.boundingBox;
    var dX = Math.abs(bbox.max.x - bbox.min.x);
    var dY = Math.abs(bbox.max.y - bbox.min.y);
    var dZ = Math.abs(bbox.max.z - bbox.min.z);
    
    
    var repeat = Math.max(dX,dY,dZ) / fitTo;
    
      texture.repeat.set( repeat , repeat );
    return texture;
    }

  const texture = getScaledTexture(nodes.eps.geometry,0.03);



  // Cursor showing current color
  const [hovered, set] = useState(null)
  useEffect(() => {
       document.body.style.cursor = `url('/mouse-termotec.png'), auto`
  }, [hovered])

 
  return (
    
    <group ref={ref} dispose={null} rotation={[-1.2, 0, -0.2]} position={[0,0.03,0]}>
      <group position={[-0.52, -0.66, 0]}>
      <mesh geometry={nodes.eps.geometry} material={nodes.eps.material} material-map={texture}/>
        </group>
        <group position={[-0.545, -0.66, -0.004]}>
        <mesh geometry={nodes.inferior.geometry} material={nodes.inferior.material} material-color={snap.items.inferior}/>
        </group>
        <group position={[-0.52, -0.66, 0.03]}>
        <mesh geometry={nodes.superior.geometry} material={nodes.superior.material} material-color={snap.items.superior}/>
        </group>
    </group>
    
  )
  
}


function HandleChangeSuperior(event) {
  state.items.superior = event.target.value
}

function HandleChangeInferior(event) {
  state.items.inferior = event.target.value
}


function HandleChangeTexture(event) {
  state.texture = event.target.value;
}

export default function App(props) {
  

  state.items.inferior = props.inferior;
  state.items.superior = props.superior;
  state.texture = props.eps;
  return (
    <>
      <Canvas concurrent pixelRatio={[1, 1.5]} camera={{ position: [0, 0, 1.75]},{zoom: 7}}>
          <ambientLight intensity={0.5} />
          <spotLight intensity={0.5} angle={0.1} penumbra={1} position={[5, 25, 20]} />
          <Suspense fallback={false}>
          <Environment files={props.hdr} />
            <Termotec />
          </Suspense>
        <OrbitControls  enableZoom={true} enablePan={false}/>
      </Canvas>  
      
    </>
  )
}
